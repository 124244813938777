import * as React from 'react';
import './Road-map.scss';

const RoadMap = () => {
  return (
    <div className="nft-road-map">
      <h3 className="nft-road-map__title">
        <span>ARGO <br/>ROADMAP</span>
      </h3>

      <div className="nft-road-map__row">
        <div className="nft-road-map__aim">
          <h4 className="nft-road-map__aim-text">
            <span className="underlined">Build a</span>
            <br/>
            <span className="underlined">community of</span>
            Creators and storytellers
          </h4>
        </div>
        <ul className="nft-road-map__detail">
          {/*<li className="nft-road-map__detail-item">*/}
          {/*  <a href="https://www.instagram.com/thebutcherbilly/?hl=en" target="_blank" rel="noreferrer" className="decorated-link">BUTCHER BILLY</a>*/}
          {/*  &nbsp;*/}
          {/*  CREATED AN ORIGINAL NFT ART COLLECTION INSPIRED BY THE FILM “BAD ASSISTANT”*/}
          {/*</li>*/}
          <li className="nft-road-map__detail-item">
            THE NFT IS YOUR UNIQUE ACCESS TO THE WORLD OF CREATORS ON ARGO AND BEYOND
          </li>
          <li className="nft-road-map__detail-item">
            we are making web3 tools available to everyone
          </li>
        </ul>
      </div>

      <div className="scissor-divider">
        <div className="hr"/>
      </div>

      <div className="nft-road-map__row">
        <div className="nft-road-map__aim">
          <h4 className="nft-road-map__aim-text">
            Empower<br/>
            <span className="underlined">the Creators</span>
          </h4>
        </div>
        <ul className="nft-road-map__detail">
          <li className="nft-road-map__detail-item">
            to own their success and be in control of their IP
          </li>
          <li className="nft-road-map__detail-item">
            AND BUILD A DEEPER RELATIONSHIP WITH their FANS
          </li>
        </ul>
      </div>

      <div className="scissor-divider">
        <div className="hr"/>
      </div>

      <div className="nft-road-map__row">
        <div className="nft-road-map__aim">
          <h4 className="nft-road-map__aim-text">
            <span className="underlined">Create a</span>
            <br/>
            <span className="underlined">Metaverse of IP</span>
            {/*from the best Creators worldwide*/}
          </h4>
        </div>
        <ul className="nft-road-map__detail">
          <li className="nft-road-map__detail-item">
            with more ownership and accountability
          </li>
          <li className="nft-road-map__detail-item">
            with less scrolling and more engagement
          </li>
        </ul>
      </div>

      {/*<div className="scissor-divider left">*/}
      {/*  <div className="hr"/>*/}
      {/*</div>*/}

      {/*<div className="nft-road-map__row">*/}
      {/*  <div className="nft-road-map__aim">*/}
      {/*    <h4 className="nft-road-map__aim-text">*/}
      {/*      <div className="lg">*/}
      {/*        PLAY TO*/}
      {/*        <br/>*/}
      {/*        <span className="underlined">EARN GAME</span>*/}
      {/*      </div>*/}
      {/*      <div className="sm">*/}
      {/*        <span className="underlined">PLAY TO EARN GAME</span>*/}
      {/*      </div>*/}
      {/*    </h4>*/}
      {/*  </div>*/}
      {/*  <ul className="nft-road-map__detail">*/}
      {/*    <li className="nft-road-map__detail-item">*/}
      {/*      IF THE FILM REACHES ITS GOALS, THE FILMMAKERS WILL HOST A GAME OF HEROES VS VILLAINS*/}
      {/*    </li>*/}
      {/*    <li className="nft-road-map__detail-item">*/}
      {/*      IT’S A GAME TO THE DEATH SO BE READY TO HAVE YOUR CHARACTER NFTs BURNED*/}
      {/*    </li>*/}
      {/*    <li className="nft-road-map__detail-item">*/}
      {/*      FOR NOW ALL THE GAMES ARE TAKING PLACE ON DISCORD*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*</div>*/}

      {/*<div className="scissor-divider right">*/}
      {/*  <div className="hr"/>*/}
      {/*</div>*/}

      {/*<div className="nft-road-map__row">*/}
      {/*  <div className="nft-road-map__aim">*/}
      {/*    <h4 className="nft-road-map__aim-text">*/}
      {/*      <span className="underlined">ARGO COINS</span>*/}
      {/*    </h4>*/}
      {/*  </div>*/}
      {/*  <ul className="nft-road-map__detail">*/}
      {/*    <li className="nft-road-map__detail-item">*/}
      {/*      ARGO COINS UNLOCK UNIQUE PRIZES FROM THE FILMS*/}
      {/*    </li>*/}
      {/*    <li className="nft-road-map__detail-item">*/}
      {/*      YOU WILL BE ABLE TO EARN COINS BY SUPPORTING FILMS ON ARGO AND BY PARTICIPATING IN NFT CHALANGES*/}
      {/*    </li>*/}
      {/*    /!*<li className="nft-road-map__detail-item">*!/*/}
      {/*    /!*  SOON, YOU WILL BE ABLE TO INVEST ARGO COINS INTO THE FILMS THAT YOU THINK WILL BECOME HITS, AND OWN A PART*!/*/}
      {/*    /!*  OF THAT FILM*!/*/}
      {/*    /!*</li>*!/*/}
      {/*  </ul>*/}
      {/*</div>*/}

      {/*<div className="scissor-divider right">*/}
      {/*  <div className="hr"/>*/}
      {/*</div>*/}

      {/*<div className="nft-road-map__row">*/}
      {/*  <div className="nft-road-map__aim">*/}
      {/*    <h4 className="nft-road-map__aim-text">*/}
      {/*      <span className="underlined">FUTURE RELEASES</span>*/}
      {/*    </h4>*/}
      {/*  </div>*/}
      {/*  <ul className="nft-road-map__detail">*/}
      {/*    <li className="nft-road-map__detail-item">*/}
      {/*      MORE FILMS WILL BE RELEASED AS NFT COLLECTIONS, MORE HEROES & VILLAINS WILL EMERGE*/}
      {/*    </li>*/}
      {/*    <li className="nft-road-map__detail-item">*/}
      {/*      THE NFT  CHARACTERS  WILL BE INTERACTIVE ACROSS DIFFERENT FILMS AS THEY EVOLVE*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*</div>*/}
    </div>
  );
}

export default RoadMap;
