import * as React from 'react';
import * as ReactDOM from 'react-dom';

import App from './App';
import { BrowserRouter } from 'react-router-dom';

// @ts-ignore
declare global {
  // tslint:disable-next-line
  interface Window {
    web3: any;
    ethereum: any;
    Web3Modal: any;
    Box: any;
    box: any;
    space: any;

    [name: string]: any;
  }
}

ReactDOM.render(<BrowserRouter><App/></BrowserRouter>, document.getElementById('root'));
