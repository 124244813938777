import * as React from 'react';
import { SideMenuStates } from '../sidemenu/Sidemenu.animation';
import { environment } from '../../../environments/environment';
import { Sidemenu } from '../sidemenu/Sidemenu';
import './Header.scss';
import { Link,
  // NavLink
} from 'react-router-dom';

export interface MenuItem {
  title: string;
  route: string;
  isActive: boolean;
  isExternal: boolean;
}

interface IHeaderState {
  websiteUrl: string;
  streamUrl: string;
  nftUrl: string;
  isOpenSideMenu: SideMenuStates;
  hasActiveMenu: boolean;
  isHeaderFixed: boolean;
}

const INITIAL_STATE: IHeaderState = {
  websiteUrl: environment.websiteUrl,
  streamUrl: environment.streamUrl,
  nftUrl: environment.nftUrl,
  isOpenSideMenu: SideMenuStates.CLOSED,
  hasActiveMenu: true,
  isHeaderFixed: false,
};

class Header extends React.Component<any, any> {
  public state: IHeaderState;

  constructor(props: any) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  public componentDidMount() {
    window.addEventListener('scroll', this.onWindowScroll);
  }

  public componentWillUnmount() {
    window.removeEventListener('scroll', this.onWindowScroll);
  }

  private onWindowScroll = () => {
    const fixPosition = 20;

    this.setState({
      isHeaderFixed: (
        document.body.scrollTop > fixPosition ||
        document.documentElement.scrollTop > fixPosition
      ),
    });
  }

  private openSideMenu = (): void => {
    this.setState((state: IHeaderState) => ({
      ...state,
      isOpenSideMenu: SideMenuStates.OPENED,
    }));
  }

  private closeSideMenu = (): void => {
    this.setState((state: IHeaderState) => ({
      ...state,
      isOpenSideMenu: SideMenuStates.CLOSED,
    }));
  }

  public render = () => {
    const {
      // websiteUrl,
      streamUrl,
      // nftUrl,
      isOpenSideMenu,
      hasActiveMenu,
      isHeaderFixed,
    } = this.state;

    const pagesMenu: MenuItem[] = [
      { title: 'Stream', route: `${streamUrl}/`, isActive: false, isExternal: true },
      { title: 'About', route: `/#about`, isActive: false, isExternal: false },
      { title: 'BAD ASSISTANT', route: `/#event`, isActive: false, isExternal: false },
      // { title: 'PRIZES', route: `/#prizes`, isActive: false, isExternal: false },
      { title: 'ARGO ROADMAP', route: `/#roadmap`, isActive: false, isExternal: false },
    ];

    const headerTransparentClass = !isHeaderFixed ? 'transparent' : '';
    return (
      <React.Fragment>
        <header className={`header ${headerTransparentClass}`}>
          <div className="main-container">
            <div className="login-menu">
              <button onClick={this.openSideMenu}
                      className="main-menu-button"
              ><span/><span/><span/><span/></button>
            </div>
            <div className={`menu ${hasActiveMenu}`}>
              {
                pagesMenu.map((item: MenuItem) => {
                  // Return the element. Also pass key
                  return item.isExternal ? (
                    <a href={item.route}
                       key={item.route}
                       className={`link ${item.isActive ? 'is-active' : ''}`}
                    >
                      {item.title}
                    </a>
                  ) : (
                    <Link to={item.route}
                          key={item.route}
                          className={`link ${item.isActive ? 'is-active' : ''}`}
                    >
                      {item.title}
                    </Link>
                  );
                })
              }
            </div>

            {/*<NavLink*/}
            {/*  to="/mint" className="header__mint-button"*/}
            {/*>*/}
            {/*  MINT*/}
            {/*</NavLink>*/}
          </div>
        </header>
        <Sidemenu sideMenuState={isOpenSideMenu} closeMenu={this.closeSideMenu}/>
      </React.Fragment>
    );
  };
}

export default Header;
