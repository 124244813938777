import './Social.scss';
import config from "../../../config";

const Social = () => {
  const eventTrack = (action: string) => {
    window.gtag('event', action, { 'event_name': action })
  }

  return (
    <div className="social">
      {/*<a href={ config.discord }*/}
      {/*   className="link discord"*/}
      {/*   target="_blank"*/}
      {/*   rel="noreferrer"*/}
      {/*   onClick={eventTrack.bind(this, 'discord_click')}*/}
      {/*>*/}
      {/*  Discord*/}
      {/*</a>*/}
      <a href={ config.twitter }
         className="link twitter"
         target="_blank"
         rel="noreferrer"
         onClick={eventTrack.bind(this, 'twitter_click')}
      >
        Twitter
      </a>

      <a href={ config.instagram }
         className="link instagram"
         target="_blank"
         rel="noreferrer"
         onClick={eventTrack.bind(this, 'instagram_click')}
      >
        Instagram
      </a>

      <a href={ config.tiktok }
         className="link tiktok"
         target="_blank"
         rel="noreferrer"
         onClick={eventTrack.bind(this, 'tiktok_click')}
      >
        Tiktok
      </a>
      {/*<a href={ config.opensea }*/}
      {/*   rel="noreferrer"*/}
      {/*   className="link opensea"*/}
      {/*   target="_blank"*/}
      {/*   onClick={eventTrack.bind(this, 'opensea_click')}*/}
      {/*>Opensea</a>*/}
    </div>
  );
}

export default Social;
