import image1 from '../../assets/img/instruction/1.png';
import image2 from '../../assets/img/instruction/2.png';
import image3 from '../../assets/img/instruction/3.png';
import image4 from '../../assets/img/instruction/4.png';
import image5 from '../../assets/img/instruction/5.png';
import image6 from '../../assets/img/instruction/6.jpg';
import * as React from 'react';
import styles from './Instruction.module.scss';
import Social from '../shared/social/Social';
import { Link } from 'react-router-dom';

const Instruction = () => {
  return (
    <React.Fragment>
      <div className={styles.instructionPage}>
        <h1 className={styles.title}>Argo Minting Instructions</h1>
        <p className={styles.text}>
          <span className={styles.number}>1</span>
          Open <Link to="/" target="_blank" rel="noreferrer" className={styles.decoratedLink}>https://nft.watchargo.com/</Link> and click 'MINT'
        </p>
        <img className={styles.image} src={image1} alt="instruction"/>

        <p className={styles.text}>
          <span className={styles.number}>2</span>
          MetaMask extension will connect automatically. If it didn't happen click on it to open. Enter your password in the new window.
        </p>
        <img className={styles.image} src={image2} alt="instruction"/>

        <p className={styles.text}>
          <span className={styles.number}>3</span>
          Connect the desired addresses to the MetaMask.
        </p>
        <img className={styles.image} src={image3} alt="instruction"/>
        <img className={styles.image} src={image4} alt="instruction"/>

        <p className={styles.text}>
          <span className={styles.number}>4</span>
          Enter the number of Argo NFT you would like to mint in the number box. Then, hit the MINT button.
        </p>
        <img className={styles.image} src={image5} alt="instruction"/>

        <p className={styles.text}>
          <span className={styles.number}>5</span>
          MetaMask will ask you to confirm your transaction, and it will estimate the gas fees for your purchase.
        </p>
        <img className={styles.image} src={image6} alt="instruction"/>

        <p className={styles.text}>
          <span className={styles.number}>6</span>
          Your transaction should be confirmed on the network shortly. Once this has happened, you can view your Argo NFT by heading to <a href="https://opensea.io/collection/argo-bad-assistant" target="_blank" rel="noreferrer" className={styles.decoratedLink}>https://opensea.io/collection/argo-bad-assistant</a> and signing in with the same wallet you used to purchase your NFT.
        </p>
      </div>
      <div className={styles.socialBlock}>
        <Social/>
      </div>
    </React.Fragment>
  );
}

export default Instruction;
