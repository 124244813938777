import * as React from 'react';
import Header from './components/shared/header/Header';
import './scss/main.scss';
// import Mint from './components/mint/Mint';
import { Route, Routes } from 'react-router-dom';
import MainPage from './components/main-page/Main-page';
import ScrollToTop from './components/shared/scroll-to-top/SctollToTop';
import Instruction from './components/instruction/Instruction';
import SuccessStripe from './components/success-stripe/SuccessStripe';
import SwiperCore, { Autoplay } from 'swiper';

const App = () => {
  SwiperCore.use([Autoplay])

  return (
    <React.Fragment>
      <ScrollToTop/>
      <Header/>

      <Routes>
        <Route path="/" element={<MainPage/>}/>
        {/*<Route path="/mint" element={<Mint/>}/>*/}
        <Route path="/instruction" element={<Instruction/>}/>
        <Route path="/success" element={<SuccessStripe/>}/>
      </Routes>
    </React.Fragment>
  );
}

export default App;
