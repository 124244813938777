import * as React from 'react';
import { SideMenuStates } from './Sidemenu.animation';
import { environment } from '../../../environments/environment';
import './Sidemenu.scss';
import logo from '../../../assets/img/argo-logo-white.svg';
import { CSSTransition } from 'react-transition-group';

interface ISideMenuProps {
  sideMenuState: SideMenuStates,
  closeMenu: () => void;
}

// const INITIAL_PROPS: ISideMenuProps = {
//   sideMenuState: SideMenuStates.CLOSED,
//   closeMenu: () => {}
// };

export const Sidemenu = ({ sideMenuState, closeMenu }: ISideMenuProps) => {
  const stopPropagation = (e: any): void => {
    e.stopPropagation();
  }
  const websiteUrl = environment.websiteUrl;
  const appleUrl = environment.appleUrl;
  const googleUrl = environment.googleUrl;
  const rokuUrl = environment.rokuUrl;

  return (
    <CSSTransition
      in={sideMenuState === SideMenuStates.OPENED}
      out={sideMenuState === SideMenuStates.CLOSED}
      timeout={300}
      unmountOnExit
      classNames="slide"
    >
      <div onClick={closeMenu}
           className="full-page-wrapper"
      >
        <div className="side-menu" onClick={stopPropagation}>
          <div className="wrapper">
            <div className="side-header">
              <button onClick={closeMenu} type="button" className="main-menu-button"
              ><span/><span/><span/><span/></button>
              <a className="logo" href="https://watchargo.com/">
                <img className="img" src={logo} alt="Argo logo"/>
              </a>
            </div>
            <div className="sidemenu-links">
              <ul className="sidemenu-nav">
                <li><a className="link" href={`${ websiteUrl }/#whatsArgo`}>home</a></li>
                {/*<li><a className="link" href={`${ websiteUrl }/filmmakers/`}>for filmmakers</a></li>*/}
                {/*<li><a className="link" href={`${ websiteUrl }/viewers/`}>for fans</a></li>*/}
                <li><a className="link" href={`${ websiteUrl }/contact/`}>Contact us</a></li>
                <li><a className="link" href={`${ websiteUrl }/blog/`}>blog</a></li>
                <li><a className="link" href={`${ websiteUrl }}#popup`}>Submit a film</a></li>
                {/*<li><a className="link" href={`${ websiteUrl }/faq/`}>Help center</a></li>*/}
              </ul>
            </div>
            <div className="side-footer">
              <div className="side-footer-row">
                <a className='side-apple-button' href={appleUrl}>apple</a>
                <a className='side-google-button' href={googleUrl}>google</a>
                <a className='side-roku-button' href={rokuUrl}>roku</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}
