// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  websiteUrl: 'https://watchargo.com',
  streamUrl: 'https://web.watchargo.com',
  // nftUrl: 'https://nft.argomedia.co',
  nftUrl: 'https://nft.watchargo.com',
  appleUrl: 'https://apps.apple.com/app/argo-short-films/id1508172165',
  googleUrl: 'https://play.google.com/store/apps/details?id=argomedia.app',
  rokuUrl: 'https://channelstore.roku.com/details/652e095f6e3134c32f65206625caa27c/argo',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
