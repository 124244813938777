// interface ISocialConfig {
//   twitter: string;
//   discord: string;
// }

const config = {
  twitter: 'https://twitter.com/Watch_Argo',
  instagram: 'https://www.instagram.com/watch.argo/',
  tiktok: 'https://www.tiktok.com/@watch.argo',
  discord: 'https://discord.gg/pemxqpYg9H',
  opensea: 'https://opensea.io/collection/argo-bad-assistant',
  priceIncreaseInSeconds: 86400,
}

export default config;
