import * as React from 'react';
import styles from './SuccessStripe.module.scss';
import Social from '../shared/social/Social';
import { Link } from 'react-router-dom';

const Instruction = () => {
  return (
    <div className={styles.layout}>
      <div className={styles.SuccessPage}>
        <h1 className={styles.title}>CONGRATULATIONS!</h1>
        <p>The amount of NFTs you mint will determine the unique strength and traits of your Character NFTs. Your NFTs
          will be revealed within 48 hours. <br/><br/>
          As a token of gratitude you get a free month of Argo Full Access. Check your email for registration
          instructions. </p>
        <Link to="/" target="_self" rel="noreferrer" className={styles.link}>Back To Site</Link>
      </div>
      <div className={styles.peopleBlock}>
        <div className={styles.people}/>
      </div>
      <div className={styles.socialBlock}>
        <Social/>
      </div>
    </div>
  );
}

export default Instruction;
