import * as React from 'react';
import './Main-page.scss';
import Promo from './promo/Promo';
import RoadMap from './road-map/Road-map';
import { useLocation } from 'react-router-dom';
import List from '../shared/list/List';
import { useEffect } from 'react';
import Social from '../shared/social/Social';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

import poster1 from '../../assets/img/cards/e1.jpg';
import poster3 from '../../assets/img/cards/e2.jpg';
import poster5 from '../../assets/img/cards/e3.jpg';
import poster7 from '../../assets/img/cards/e4.jpg';
import poster9 from '../../assets/img/cards/e5.jpg';
import poster11 from '../../assets/img/cards/e6.jpg';

import poster2 from '../../assets/img/cards/p1.jpg';
import poster4 from '../../assets/img/cards/p2.jpg';
import poster6 from '../../assets/img/cards/p3.jpg';
import poster8 from '../../assets/img/cards/p4.jpg';
import poster10 from '../../assets/img/cards/p5.jpg';
import poster12 from '../../assets/img/cards/p6.jpg';


const MainPage = () => {
  // const [finishIn, setFinishIn] = useState(0);
  const location = useLocation();

  useEffect(() => {
    const { hash } = location;

    const removeHash = () => {
      window.history.replaceState("", document.title, window.location.pathname
        + window.location.search);
    }

    const scroll = (id: string) => {
      window.scrollTo({
        behavior: 'smooth',
        top:
        // @ts-ignore
          document.getElementById(id.split('#')[1]).getBoundingClientRect().top -
          document.body.getBoundingClientRect().top
      });

      removeHash();
    };


    if (hash) {
      scroll(hash);
    }

    // let cleanupFunction = false;

    // setFinishIn(prevState => getSecondsBeforeIncrease());

    // setInterval(() => {
    //   if(!cleanupFunction) {
    //     setFinishIn(prevState => getSecondsBeforeIncrease());
    //   }
    // }, 1000);

    // return () => {
    //   cleanupFunction = true;
    // }
  },[location]);

  return (
    <React.Fragment>
      <Promo/>

      {/*<div className="information-block">*/}
      {/*  <div className="information-block__thin-bricks"/>*/}
      {/*  <div className="information-block__content">*/}
      {/*    Introducing short films into the NFT metaverse and enabling the creators and the fans to share in the films’ success.*/}
      {/*  </div>*/}
      {/*  <div className="information-block__thick-bricks"/>*/}
      {/*</div>*/}

      <div className="mechanics-block">
        <div className="mechanics-block__header">
          <div className="mechanics-block__title">
            Argo Web<span className="mark">3</span>
          </div>
          <div className="mechanics-block__description">
            Argo is creating the first of its kind streaming platform, where you can buy Films and videos as NFTs and earn your access to your favorite channels for free!
          </div>
        </div>
      </div>

      <div className="image-block" id="event">
        <h2 className="image-block__title">
          “BAD ASSISTANT”
          <br/>
          Short film by Jason Schwartzman and Paige Klone
        </h2>
      </div>

      <div className="crew-block">
        {/*<h3 className="crew-block__title">*/}
        {/*  <span className="text">CREW<span className="mark">:</span></span>*/}
        {/*</h3>*/}
        {/*<div className="crew-block__items">*/}
        {/*  <div className="item jason">*/}
        {/*    <div className="person-info">*/}
        {/*      <div className="name">JASON SCHWARTZMAN</div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className="item paige">*/}
        {/*    <div className="person-info">*/}
        {/*      <div className="name">Paige Klone</div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}

        <Swiper
          className="crew-block__posters"
          spaceBetween={20}
          slidesPerView="auto"
          loop={true}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false
          }}
        >
          <SwiperSlide><img src={poster1} alt="Bad assistant poster"/></SwiperSlide>
          <SwiperSlide><img src={poster2} alt="Bad assistant poster"/></SwiperSlide>
          <SwiperSlide><img src={poster3} alt="Bad assistant poster"/></SwiperSlide>
          <SwiperSlide><img src={poster4} alt="Bad assistant poster"/></SwiperSlide>
          <SwiperSlide><img src={poster5} alt="Bad assistant poster"/></SwiperSlide>
          <SwiperSlide><img src={poster6} alt="Bad assistant poster"/></SwiperSlide>
          <SwiperSlide><img src={poster7} alt="Bad assistant poster"/></SwiperSlide>
          <SwiperSlide><img src={poster8} alt="Bad assistant poster"/></SwiperSlide>
          <SwiperSlide><img src={poster9} alt="Bad assistant poster"/></SwiperSlide>
          <SwiperSlide><img src={poster10} alt="Bad assistant poster"/></SwiperSlide>
          <SwiperSlide><img src={poster11} alt="Bad assistant poster"/></SwiperSlide>
          <SwiperSlide><img src={poster12} alt="Bad assistant poster"/></SwiperSlide>
        </Swiper>
        {/*<div className="crew-block__posters">*/}
        {/*  <img src={poster} alt="Bad assistant poster"/>*/}
        {/*  <img src={poster} alt="Bad assistant poster"/>*/}
        {/*  <img src={poster} alt="Bad assistant poster"/>*/}
        {/*  <img src={poster} alt="Bad assistant poster"/>*/}
        {/*  <img src={poster} alt="Bad assistant poster"/>*/}
        {/*  <img src={poster} alt="Bad assistant poster"/>*/}
        {/*  <img src={poster} alt="Bad assistant poster"/>*/}
        {/*  <img src={poster} alt="Bad assistant poster"/>*/}
        {/*  <img src={poster} alt="Bad assistant poster"/>*/}
        {/*  <img src={poster} alt="Bad assistant poster"/>*/}
        {/*  <img src={poster} alt="Bad assistant poster"/>*/}
        {/*  <img src={poster} alt="Bad assistant poster"/>*/}
        {/*  <img src={poster} alt="Bad assistant poster"/>*/}
        {/*  <img src={poster} alt="Bad assistant poster"/>*/}
        {/*</div>*/}

        <h3 className="crew-block__title">
          <span className="text">Villain <br className="spaceship"/>{'<->'}<br className="spaceship"/> Hero <br/>Which one are you? </span>
        </h3>
      </div>

      <div className="film-road-map-block">
        <div className="film-road-map-block__title">
          GAMIFYING YOUR FILM EXPERIENCE!
        </div>
        <div className="film-road-map-block__description"></div>

        <div className="sign-up-next">
          <a href="https://docs.google.com/forms/d/1qi1VUFv1j3FVush9ukfujlkmiZsiNKE3BHdkhAceX1s/viewform?edit_requested=true"
             className="item sign-up-next-link"
             target="_blank"
             rel="noreferrer"
          >SIGN UP FOR NEXT DROP</a>
        </div>

        <List/>

      </div>

      <div className="scissor-divider light">
        <div className="hr"/>
      </div>

      <div className="roadmap-block">
        {/*<div className="roadmap-block__title">*/}
        {/*  <div><span className="mark negative uppercase">Early Access</span></div>*/}
        {/*  <div><span className="text">Dec 14 - 02:00 PM PST</span></div>*/}
        {/*  <div><span className="mark negative uppercase">Public Access</span></div>*/}
        {/*  <div><span className="text">Dec 14 - 03:00 PM PST</span></div>*/}
        {/*  <div><span className="mark negative uppercase">Sale Closes</span></div>*/}
        {/*  <div><span className="text">Dec 22 - 12:00 AM PST</span></div>*/}
        {/*</div>*/}

        {/*<Link to="/mint" className="roadmap-block__mint-button">Mint</Link>*/}

        {/*<div className="roadmap-block__roadmap-items">*/}
          {/*<div className="item passed top-space">*/}
          {/*  <div className="icon roadmap-time"/>*/}
          {/*  <div className="separator"/>*/}
          {/*  <div className="info">*/}
          {/*    <div className="description">*/}
          {/*      MINTING FOR EARLY*/}
          {/*      <br/>*/}
          {/*      ACCESS MEMBERS*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className="item current top-space">*/}
          {/*  <div className="icon roadmap-cart"/>*/}
          {/*  <div className="separator"/>*/}
          {/*  <div className="info">*/}
          {/*    <div className="description">*/}
          {/*      MINTING OPEN*/}
          {/*      <br/>*/}
          {/*      TO PUBLIC*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className="item minor">*/}
          {/*  <div className="icon"/>*/}
          {/*  <div className="separator"/>*/}
          {/*  <div className="info">*/}
          {/*    <div className="description">*/}
          {/*      UNLOCK STORY MODE GAMES*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className="item">*/}
          {/*  <div className="icon roadmap-film"/>*/}
          {/*  <div className="separator"/>*/}
          {/*  <div className="info">*/}
          {/*    <div className="description">*/}
          {/*      WE ARE MAKING<br/>*/}
          {/*      THE FEATURE FILM*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className="item minor">*/}
          {/*  <div className="icon"/>*/}
          {/*  <div className="separator"/>*/}
          {/*  <div className="info">*/}
          {/*    <div className="description uppercase">*/}
          {/*      unlock play to EARN game*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        {/*</div>*/}
        {/*<div className="roadmap-block__sale-closed"/>*/}

        {/*<div className="roadmap-block__main-description">*/}
        {/*  We will close the sale when all the unique*/}
        {/*  &nbsp;*/}
        {/*  <br/>*/}
        {/*  <span className="roadmap-block__underlined-mark">*/}
        {/*    Heroes*/}
        {/*  </span>*/}
        {/*  &nbsp;*/}
        {/*  <span className="roadmap-block__underlined-mark">*/}
        {/*    and*/}
        {/*  </span>*/}
        {/*  &nbsp;*/}
        {/*  <span className="roadmap-block__underlined-mark">*/}
        {/*    Villains*/}
        {/*  </span>*/}
        {/*  &nbsp;*/}
        {/*  <span className="roadmap-block__underlined-mark">*/}
        {/*    are*/}
        {/*  </span>*/}
        {/*  &nbsp;*/}
        {/*  <span className="roadmap-block__underlined-mark">*/}
        {/*    minted*/}
        {/*  </span>*/}
        {/*  &nbsp;and the film is greenlit.*/}
        {/*</div>*/}

        <div className="roadmap-block__current-progress">
          <div className="percent">
            10%
          </div>
          <div className="separator"/>
          <div className="text">
            of proceeds go to supporting high school students mental health and suicide prevention.
          </div>
        </div>
      </div>

      {/*<div className="scissor-divider light">*/}
      {/*  <div className="hr"/>*/}
      {/*</div>*/}

      {/*<div className="prizes-block" id="prizes">*/}
      {/*  <div className="prizes">*/}
      {/*    <div className="title uppercase">Prizes may include<span className="mark">:</span></div>*/}
      {/*    <div className="cards by-three">*/}
      {/*      <div className="card small">*/}
      {/*        <div className="title uppercase"><span className="no-break">ETH</span></div>*/}
      {/*        <div className="description">*/}
      {/*          Monetary cryptocurrency*/}
      {/*          <br/>*/}
      {/*          rewards*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className="card small">*/}
      {/*        <div className="title uppercase"><span className="no-break">ARGO COINS</span></div>*/}
      {/*        <div className="description">*/}
      {/*          The key to unlocking the Film Metaverse*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className="card small">*/}
      {/*        <div className="title uppercase"><span className="no-break">Merchandise</span></div>*/}
      {/*        <div className="description">*/}
      {/*          Signed posters, comic books and merch*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="cards by-two">*/}
      {/*      <div className="card">*/}
      {/*        <div className="title uppercase"><span className="no-break">Live interactions</span></div>*/}
      {/*        <div className="description">*/}
      {/*          Live sessions with the filmmakers*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className="card">*/}
      {/*        <div className="title uppercase"><span className="no-break">Behind the scenes</span></div>*/}
      {/*        <div className="description">*/}
      {/*          Access to behind the scenes footage and special materials while the film is being made*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}

      {/*  <div className="main-prize-box">*/}
      {/*    <div className="main-prize">*/}
      {/*      <div className="card">*/}
      {/*        <div className="title">*/}
      {/*          Your name in the new Film credits and premier invitations*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}

      <div className="parallax">
        <div className="background"/>
        <div className="description">
          COLLECTION #2 COMING SOON
          <br/>
          ONLY ON ARGO
          {/*Become part of the filmmaker metaverse*/}
          {/*and invest into the films’ success*/}
          <div className="sign-up-early">
            <a href="https://docs.google.com/forms/d/1GhECIXlZEJ_E9mWNr81x7jaI36IXMHjgDT84z1WqVv0/viewform?edit_requested=true"
               className="item sign-up-early-link"
               target="_blank"
               rel="noreferrer"
            >SIGN UP FOR EARLY ACCESS</a>
          </div>
        </div>
      </div>

      <div id="roadmap">
        <RoadMap />
      </div>

      <div className="purpose-block">
        {/*<div className="title">Why is Argo creating NFTs<span className="mark">?</span></div>*/}
        {/*<div className="purpose-item">*/}
        {/*  <div className="icon chair"/>*/}
        {/*  <div className="info">*/}
        {/*    <div className="title">FOR THE FILMMAKERS</div>*/}
        {/*    <div className="description">*/}
        {/*      <p>*/}
        {/*        We want to empower more artists worldwide to tell stories that matter.*/}
        {/*        <br/>*/}
        {/*        With Argo, the films can be promoted by influencers and upvoted by the audience.*/}
        {/*        <br/>*/}
        {/*        With Argo NFT, we are creating more ways to interact with the films and build a deeper relationship with your fans.*/}
        {/*        <br/>*/}
        {/*        With every NFT-purchase, funds go directly to the filmmakers and to creating more great films.*/}
        {/*      </p>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div className="scissor-divider light">*/}
        {/*  <div className="hr"/>*/}
        {/*</div>*/}
        {/*<div className="purpose-item">*/}
        {/*  <div className="icon people"/>*/}
        {/*  <div className="info">*/}
        {/*    <div className="title">FOR THE NFT COMMUNITY</div>*/}
        {/*    <div className="description">*/}
        {/*      <p>*/}
        {/*        Imagine a Metaverse of the most talented storytellers from around the world - with Argo you can be part of it.*/}
        {/*        <br/>*/}
        {/*        With Argo NFTs, you can support the filmmakers and be part of the success of the films.*/}
        {/*        <br/>*/}
        {/*        This is just the beginning of a new way to distribute films & discover stories you love.*/}
        {/*      </p>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}

        {/*<div className="purpose-block__text">*/}
        {/*  Become part of <span className="underlined">the filmmaker metaverse</span><br/>*/}
        {/*  and invest into the films’ success*/}
        {/*</div>*/}

        <footer>
          <a href="https://etherscan.io/address/0x135f48e48792c6c38bb0db7d4a6663726c06b573" target="_blank" rel="noreferrer" className="link smart-contract">Smart Contract</a>
          <Social/>
          <a href="https://web.watchargo.com/pdf/document/version/2020/terms-and-conditions-1-0.pdf"
             target="_blank" rel="noreferrer" className="link terms decorated-link">Terms of service</a>
        </footer>
      </div>
    </React.Fragment>
  );
}

export default MainPage;
