import * as React from 'react';
import './Promo.scss';
import config from '../../../config';

const Promo = () => {
  const eventTrack = (action: string) => {
    window.gtag('event', action, { 'event_name': action })
  }

  return (
    <div className="promo">
      <div className="left-block"/>
      <div className="center-block">
        <div className="title-image">
          {/*<h1>tHe home<br/>oF ShOrt film</h1>*/}
          <h1>artists<br/>going places</h1>
        </div>
        {/*<div className="description">*/}
        {/*  Empowering independent filmmakers*/}
        {/*</div>*/}
        <div className="social-links">
          <a href={config.twitter}
             className="item twitter"
             target="_blank"
             rel="noreferrer"
             onClick={eventTrack.bind(this, 'twitter_click')}
          >Twitter</a>

          <a href={config.instagram}
             className="item instagram"
             target="_blank"
             rel="noreferrer"
             onClick={eventTrack.bind(this, 'instagram_click')}
          >Instagram</a>

          <a href={config.tiktok}
             className="item tiktok"
             target="_blank"
             rel="noreferrer"
             onClick={eventTrack.bind(this, 'tiktok_click')}
          >Tiktok</a>

          {/*<a href={config.discord}*/}
          {/*   className="item discord"*/}
          {/*   target="_blank"*/}
          {/*   rel="noreferrer"*/}
          {/*   onClick={eventTrack.bind(this, 'discord_click')}*/}
          {/*>Discord</a>*/}

          {/*<a href={config.opensea}*/}
          {/*   target="_blank"*/}
          {/*   rel="noreferrer"*/}
          {/*   className="item opensea"*/}
          {/*   onClick={eventTrack.bind(this, 'opensea_click')}*/}
          {/*>OpenSea</a>*/}
        </div>
        <div id="about"/>
      </div>
      <div className="right-block"/>
    </div>
  );
}

export default Promo;
